<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('add_shoplist_user')">基本信息</span>
      <span id="fzcolor">配置信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">报备商户号配置</h5>      
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>报备商户号</p>
          </td>
          <td>
            <p>报备商户</p>
          </td>
          <td>
            <p>第三方商户号</p>
          </td>
          <td>
            <p>关联渠道</p>
          </td>
          <td>
            <p>备注</p>
          </td>
          <td style="width: 95px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.tradeMchNo }}</p>
          </td>
          <td>
            <p>{{ v.tradeMchName }}</p>
          </td>
          <td>
            <p>{{ v.thirdMchNo }}</p>
          </td>
          <td>
            <p>{{ v.mchNo }}</p>
          </td>
          <td>
            <p>{{ v.remark }}</p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="editTab(v, i)">编辑</i
              ><i class="lianjie" @click="delTab(i, v)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addTabShow">
      <span>+</span> 新增报备商户号配置
    </h6>
    <div class="btn">
      <el-button @click="toUrl('add_shoplist_user')" class="back"
        >上一步</el-button
      >
      <el-button
        @click="submit"
        :disabled="buttonFlag"
        class="next"
        type="primary"
        >确定</el-button
      >
    </div>
    <el-dialog
      :title="editIndex == null ? '新增报备商户号配置' : '修改报备商户号配置'"
      :visible.sync="overAccountShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>报备商户号</div>
          <el-input
            v-model="formData.tradeMchNo"
            maxlength="128"
            placeholder="输入报备商户号"
            tabindex="1"
          ></el-input>
          <div class="overAccount_ul_title">第三方商户号</div>
          <el-input
            v-model="formData.thirdMchNo"
            maxlength="128"
            placeholder="输入第三方商户号"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title">备注</div>
          <el-input
            v-model="formData.remark"
            maxlength="128"
            placeholder="输入备注"
            style="width: 440px"
            tabindex="5"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>报备商户</div>
          <el-input
            v-model="formData.tradeMchName"
            maxlength="128"
            placeholder="输入报备商户"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
          <el-select
            class="inputs"
            v-model="formData.mchChannelId"
            @change="mchChange"
            placeholder="选择关联渠道"
            tabindex="4"
          >
            <el-option
              v-for="v in mchchannelsDrop"
              :key="v.mchChannelId"
              :label="v.mchNo"
              :value="v.mchChannelId"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" :disabled="butFlag" @click="addTab"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { addManual } from "@/api/user/shopList.js";
import { mchchannelsDrop } from "@/api/user/shopManager.js";
export default {
  data() {
    return {
      overAccountShow: false,
      tabData: [],
      formData: {
        tradeMchNo: "",
        tradeMchName: "",
        thirdMchNo: "",
        mchChannelId: "",
        mchChannelName: "",
        remark: "",
      },
      editIndex: null,
      mchchannelsDrop: [],
      buttonFlag: false, //防止重复点击
      butFlag: false, //防止重复点击
    };
  },
  computed: {
    ...mapState({
      storesManualDetail: "user_storesManualDetail",
    }),
  },
  created() {
    if (this.storesManualDetail) {
      this.tabData = this.storesManualDetail.storeTrades
        ? this.storesManualDetail.storeTrades
        : [];
    }
  },
  methods: {
    ...mapMutations({
      setStoresManual: "user_setStoresManual",
    }),
    // 获取关联渠道
    getDrop() {
      mchchannelsDrop(this.storesManualDetail.storeDetail.mchId).then((res) => {
        if (res) {
          this.mchchannelsDrop = res.resultData;
        }
      });
    },
    // 选择渠道
    mchChange(value) {
      this.formData.mchNo = this.mchchannelsDrop.find(
        (v) => v.mchChannelId == value
      ).mchNo;
    },
    //新增展示
    addTabShow() {
      this.formData = {
        tradeMchNo: "",
        tradeMchName: "",
        thirdMchNo: "",
        mchChannelId: "",
        mchChannelName: "",
        mchNo: "",
        remark: "",
      };
      this.getDrop();
      this.editIndex = null;
      this.buttonFlag = false;
      this.butFlag = false;

      this.overAccountShow = true;
    },
    //新增
    addTab() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.tradeMchNo) {
        this.$message.error("报备商户号必填");
        return;
      }
      if (!data.tradeMchName) {
        this.$message.error("报备商户名称必填");
        return;
      }
      if (!data.mchChannelId) {
        this.$message.error("关联渠道必选");
        return;
      }
      if (this.editIndex !== null) {
        this.tabData[this.editIndex] = data;
      } else {
        this.tabData.push(data);
      }
      this.$message.success("保存成功");
      this.butFlag = true;
      this.overAccountShow = false;
    },
    //修改
    editTab(formData, index) {
      this.formData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.overAccountShow = true;
      this.butFlag = false;
    },
    //删除
    delTab(index, value) {
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tabData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //提交
    submit() {
      if (this.tabData.length <= 0) {
        this.$message.error("至少添加一项配置");
        return;
      }
      let data = {
        storeDetail: this.storesManualDetail
          ? this.storesManualDetail.storeDetail
          : null,
        storeTrades: this.tabData,
      };
      this.setStoresManual(data);
      this.buttonFlag = true;

      addManual(this.storesManualDetail).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.setStoresManual(null);
          this.$router.push({
            name: "shoplist_mdlb",
          });
          this.buttonFlag = false;
        }
      });
    },
    // 页面跳转
    toUrl(name) {
      this.$router.push({
        name: name,
      });
    },
  },
  beforeDestroy() {
    let data = {
      storeDetail: this.storesManualDetail
        ? this.storesManualDetail.storeDetail
        : null,
      storeTrades: this.tabData,
    };
    this.setStoresManual(data);
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 18px;
  text-align: center;
  height: 33px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
